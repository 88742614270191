import React from 'react'
import Footer from './Footer'
import Header from './Header'

const Privacy = () => {
  return (
    <div>
      <Header />
      <main className="mt-10">
        {' '}
        <h1 className="text-2xl text-center font-[700]">Privacy Policy</h1>
        <div className="py-4 flex justify-center">
          <section className="px-5 sm:px-10 text-gray-600 bg-slate-100 rounded-lg">
            <p className="my-5 max-w-lg w-full mx-auto">
              Rrudiment Software Private Limited (“Fruittful”, “we”, “us”, or
              “our”) values your privacy. It is our policy to respect your
              privacy and in this document we describe how we collect, use, and
              disclose information that we obtain about visitors to our
              websites, apps and devices, including but not limited to, our
              websites, fruittful.com, app.fruittful.com and other sites,
              applications and extensions we may own and operate through The App
              Store, Google Play Store, Chrome Extensions Store, Firefox store
              and others that have features to allow users to gain access to and
              optimize their performance on YouTube (collectively, the
              “Services”).
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              We only ask for personal information when we truly need it to
              provide a service to you. By visiting our Site or using any of our
              Services, you acknowledge that your personal information will be
              handled as described in this Policy. Visiting our Site, use of our
              Services, and any dispute over privacy, is subject to our Terms of
              Use, including its applicable limitations on damages and the
              resolution of disputes.
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              <b>1. Information We Collect About You</b>
              <br />
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              We only retain collected information for as long as necessary to
              provide you with your requested service. What data we store, we'll
              protect within commercially acceptable means to prevent loss and
              theft, as well as unauthorised access, disclosure, copying, use or
              modification.
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              We collect information about you directly through your use of our
              Site and Services. All visitors to our Site may browse without
              using our Services, however, to make the most use of our Services
              you must provide certain information which includes the following:
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              Information Provided by You Directly
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              Common Identifiers, being your first and/or last name and
              initials; Login information, in particular, your email address;
              Contact information, including email address and phone number;
              Financial Information, including your credit card details, but not
              your CVV number; and details of your YouTube channel, as
              applicable.
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              Information We Collect Automatically
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              We automatically collect information about your use of our Site
              and Services through cookies or other tracking technologies: your
              domain name; your device ID; your browser type and operating
              system; web pages you view; links, buttons, or other elements you
              click; mouse position; text, images, audio and videos from pages
              you view; your IP address; the length of time you visit our Site
              and/or use our Services; and the referring URL, or the webpage
              that led you to our Site. We may combine information automatically
              collected with other information that we have collected about you.
              Please see the section “Cookies and Other Tracking Mechanisms”
              below for more information.
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              Information Provided Voluntarily
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              We invite you to post content to our Services, including your
              comments, pictures, and any other information that you would like
              to be available on our Services. If you post content to our
              Services, all of the information that you post and your web alias
              will be available to all visitors to our Site and users of our
              Services. If you post your own content on our Site or Services,
              your posting may become public and we cannot prevent such
              information from being used in a manner that may violate this
              Policy, the law, or your personal privacy.
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              Information Provided by Third-Party Services
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              We may collect some data about your YouTube channels as permitted
              by YouTube's Terms of Service for use by our tools. By using
              YouTube Content within our Sites and Services you are agreeing to
              be bound to
              <a
                href="https://www.youtube.com/static?template=terms"
                className="text-blue-500 font-semibold underline underline-offset-1 mx-1"
              >
                YouTube's Terms of Service
              </a>
              and
              <a
                href="https://policies.google.com/privacy?hl=en-US"
                className="mx-1 text-blue-500 font-semibold underline underline-offset-1"
              >
                Google’s Privacy Policy.
              </a>
              Additionally, when you sign-up for a Fruittful account, you'll
              need to authenticate to our Services through a method provided by
              Google. In that case, you authorize us to access Personal
              Information and other data held by Google on an ongoing basis in
              order to provide the Services. Some of our services utilize the
              YouTube API services to accomplish certain tasks. The use of
              information received from the YouTube API services &amp; Google
              APIs as well as our usage of the YouTube API services as part of
              the services we offer, will adhere to the
              <a
                href="https://developers.google.com/youtube/terms/developer-policies"
                className="mx-1 text-blue-500 font-semibold underline underline-offset-1"
              >
                YouTube API Developer Policies,
              </a>
              <a
                href="https://developers.google.com/youtube/terms/api-services-terms-of-service"
                className="mx-1 text-blue-500 font-semibold underline underline-offset-1"
              >
                YouTube API Developer Terms of Service,
              </a>
              <a
                href="https://developers.google.com/terms/api-services-user-data-policy"
                className="mx-1 text-blue-500 font-semibold underline underline-offset-1"
              >
                Google API Services User Data Policy
              </a>
              ,including the Limited Use requirements and
              <a
                href="https://developer.chrome.com/docs/webstore/user_data/"
                className="mx-1 text-blue-500 font-semibold underline underline-offset-1"
              >
                Chrome Web Store User Data Policy.
              </a>
              Users can revoke access to data collected via YouTube API Services
              by updating their preferences in
              <a
                href="https://myaccount.google.com/permissions"
                className="mx-1 text-blue-500 font-semibold underline underline-offset-1"
              >
                Google security settings.
              </a>
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              <b>2. How We Use Your Information</b>
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              We use your information, including your personal information, for
              the following purposes:
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              Providing and Improving Our Services. To provide our Services to
              you; to communicate with you about your use of our Services; to
              respond to your inquiries; to perform technical operations, such
              as updating our Site; and for other customer service and support
              purposes.
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              To tailor the content and information that we may send or display
              to you; to offer personalized help and instructions; and to
              otherwise personalize your experiences while using our Services.
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              Marketing. To provide you with news and newsletters, special
              offers, and promotions; to contact you about products or
              information we think may interest you; and for other marketing,
              advertising, and promotional purposes. We may provide you with
              such updates and promotional materials via email.
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              Research and Analytics. To analyze how you interact with our
              Services; to monitor and analyze usage and activity trends; and
              for other research, analytical, and statistical purposes both on
              an aggregated and individualized basis including to respond to
              user desires and preferences.
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              Protecting Rights and Interests. To protect the safety, rights,
              property, or security of Rrudiment Software Private Limited, the
              Services; to detect, prevent, or otherwise address fraud,
              security, or technical issues; to prevent or stop activity which
              Rrudiment Software Private Limited, in its sole discretion, may
              consider to be, or to pose a risk of being, an illegal, unethical,
              or legally actionable activity; to use as evidence in litigation;
              and to enforce this Policy or our Terms of Use.
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              Legal Compliance. To comply with applicable legal or regulatory
              obligations, including as part of a judicial proceeding; to
              respond to a subpoena, warrant, court order, or other legal
              process; or as part of an investigation or request, whether formal
              or informal, from law enforcement or a governmental authority.
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              <b>3. How We Share Your Information</b>
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              We may share your information, including personal information, as
              follows:
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              Affiliates. We may disclose the information we collect from you to
              our affiliates or subsidiaries; however, if we do so, their use
              and disclosure of your personally identifiable information will be
              subject to this Policy.
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              Service Providers. We may disclose the information we collect from
              you to third party vendors, service providers, contractors or
              agents who perform functions on our behalf, including but not
              limited to, Google, Facebook Advertising, Twitter, Mixpanel etc
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              We also disclose information in the following circumstances:
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              Business Transfers. If we are or may be acquired by or merged with
              another company, if any of our assets are transferred to another
              company, or as part of a bankruptcy or insolvency proceeding, we
              may transfer the information we have collected from you to the
              other company.
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              In Response to Legal Process. We also may disclose the information
              we collect from you in order to comply with the law, a judicial
              proceeding, court order, or other legal process, such as in
              response to a court order or a subpoena.
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              To Protect Us and Others. We also may disclose the information we
              collect from you where we believe it is necessary to investigate,
              prevent, or take action regarding illegal activities, suspected
              fraud, situations involving potential threats to the safety of any
              person, violations of our Terms of Use, or this Policy, or as
              evidence in litigation in which we are involved.
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              Aggregate and De-Identified Information. We may share aggregate or
              de-identified information about users with third parties for
              marketing, advertising, research or similar purposes.
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              <b> 4. Our Use of Cookies and Other Tracking Mechanisms </b>
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              We and our third-party service providers use cookies and other
              tracking mechanisms to track information about your use of our
              Site or Services. We may combine this information with other
              personal information we collect from you (and our third-party
              service providers may do so on our behalf).
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              Cookies. A cookie is a text file that a website transfers to your
              computer’s hard drive for record-keeping purposes. Some cookies
              allow us to make it easier for you to navigate our Site, while
              others are used to allow us to track your activities while using
              our Site or our Services. There are two types of cookies: session
              and persistent cookies. Session cookies exist only during an
              online session. They disappear from your computer when you close
              your browser or turn off your computer. We use session cookies to
              allow our systems to uniquely identify you during a session or
              while you are browsing the Site.
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              This allows us to verify your identity as you use our Services.
              Persistent cookies remain on your computer after you have closed
              your browser or turned off your computer. We use persistent
              cookies to track aggregate and statistical information about user
              activity, and to display advertising on our Site. To see a full
              list of our cookies and details of why we use them, please contact
              us.
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              Most web browsers automatically accept cookies, but if you prefer,
              you can edit your browser options to block them in the future. The
              Help portion of the toolbar on most browsers will tell you how to
              prevent your computer from accepting new cookies, how to have the
              browser notify you when you receive a new cookie, or how to
              disable cookies altogether. Visitors to our Site who disable
              cookies will be able to browse certain areas of the Site, but some
              features may not function (e.g. you may not be able to stay logged
              into your account).
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              Local Storage Objects. We may use Flash Local Storage Objects
              (“Flash LSOs”) to store your Site preferences and to personalize
              your visit. Flash LSOs are different from browser cookies because
              of the amount and type of data stored. Typically, you cannot
              control, delete, or disable the acceptance of Flash LSOs through
              your web browser. For more information on Flash LSOs, or to learn
              how to manage your settings for Flash LSOs, go to the Adobe Flash
              Player Help Page, choose “Global Storage Settings Panel” and
              follow the instructions. To see the Flash LSOs currently on your
              computer, choose “Website Storage Settings Panel” and follow the
              instructions to review and, if you choose, to delete any specific
              Flash LSO.
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              Clear GIFs, pixel tags and other technologies. Clear GIFs are tiny
              graphics with a unique identifier, similar in function to cookies.
              In contrast to cookies, which are stored on your computer’s hard
              drive, clear GIFs are embedded invisibly on web or mobile
              application pages. We may use clear GIFs (a.k.a. web beacons, web
              bugs or pixel tags), in connection with our Site to, among other
              things, track the activities visitors to our Site, help us manage
              content, and compile statistics about usage of our Site. We and
              our third-party service providers also use clear GIFs in HTML
              emails to our customers, to help us track email response rates,
              identify when our emails are viewed, and track whether our emails
              are forwarded.
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              Third-Party Analytics. We use Google Analytics as a third-party
              analytics company, to evaluate use of our Site and Services. We
              use this tool to help us understand use of, and to improve, our
              Site and Services, performance, and user experiences. Google
              Analytics may use cookies and other tracking technologies, such as
              web beacons or local storage objects (LSOs), to perform their
              services. To learn more about Google’s privacy practices, please
              review the Google privacy policy at
              https://www.google.com/policies/privacy/. You can also download
              the Google Analytics Opt-out Browser Add-on to prevent their data
              from being used by Google Analytics at
              https://tools.google.com/dlpage/gaoptout.
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              Do Not Track. Currently, our systems do not recognize browser
              “do-not-track” requests, but we do not track your activities after
              you leave our Site. You may, however, disable certain tracking as
              discussed in this section (e.g. by disabling cookies).
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              Users in the United States may opt out of many third-party ad
              networks. For example, you may go to the Digital Advertising
              Alliance (“DAA”) Consumer Choice Page for information about opting
              out of interest-based advertising and their choices regarding
              having information used by DAA companies. You may also go to the
              Network Advertising Initiative (“NAI”) Consumer Opt-Out Page for
              information about opting out of interest-based advertising and
              their choices regarding having information used by NAI members. If
              you are in the EU, you may opt-out of certain third-party cookies
              that we and other websites may use for targeted advertising
              through the European Interactive Digital Advertising Alliance
              (EDAA) Your Online Choices Page or www.aboutads.info.
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              Opting out from one or more companies listed on the DAA Consumer
              Choice Page or the NAI Consumer Opt-Out Page will opt you out from
              those companies’ delivery of interest-based content or ads to you,
              but it does not mean you will no longer receive any advertising
              through our Site or on other websites.
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              <b> 5. Third-Party Links </b>
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              Our Site and Services may contain links to third-party websites.
              Any access to and use of such linked websites is not governed by
              this Policy, but instead is governed by the privacy policies of
              those third-party websites. We are not responsible for the
              information practices of such third-party websites.
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              <b> 6. Security of Your Personal Information </b>
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              We have implemented reasonable precautions to protect the
              information we collect from loss, misuse, and unauthorized access,
              disclosure, alteration, and destruction. Please be aware that
              despite our efforts, no data security measures can guarantee
              security.
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              You should take steps to protect against unauthorized access to
              your password, phone, and computer by, among other things, signing
              off after using a shared computer, choosing a robust password that
              nobody else knows or can easily guess, and keeping your log-in and
              password private. We are not responsible for any lost, stolen, or
              compromised passwords or for any activity on your account via
              unauthorized password activity. Only employees who need personal
              information to perform a specific job are granted access to it.
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              <b> 7. Contact Us </b>
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              If you have questions about the privacy aspects of our Site or
              Services, or would like to make a complaint, please contact us at
              support@fruittful.com.
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              This policy is effective as of 18th March 2024.
            </p>
          </section>
        </div>
      </main>

      <Footer />
    </div>
  )
}

export default Privacy
