import React from 'react'

interface IProps {
  children: React.ReactNode
}

const Main = ({children}: IProps) => {
  return (
    <main className="min-h-screen max-w-screen-2xl px-5 mx-auto">
      {children}
    </main>
  )
}

export default Main
