import React from 'react'
import Footer from './Footer'
import Header from './Header'

const Contact = () => {
  return (
    <div>
      <Header />
      <main className="mt-10 h-[calc(100vh-177px)]">
        <h1 className="text-2xl text-center font-[700]">Contact us</h1>
        <div className="py-4 flex flex-row justify-center">
          <section className="px-5 sm:px-10 text-gray-600 bg-slate-100 mt-1 p-5">
            <div className="w-full md:w-[500px]">
              <p>
                <b>Fruittful</b> is built with ❤️ by:
              </p>
              <p className="mt-5 mb-1 max-w-lg w-full">
                <b>Rrudiment Software Private Limited </b>
              </p>
              <p className="text-sm">
                14, Vijaya Apt, Shahu Col., <br />
                Karve Nagar, <br />
                Pune - 411052, MH, India <br />
                <br />
                Email:
                <a
                  href="mailto:support@fruittful.com"
                  className="text-sm text-gray-500 hover:text-gray-900 ml-1"
                >
                  support@fruittful.com
                </a>
                <br />
                <br />
                Tel: +91 9762700483
              </p>
            </div>
          </section>
        </div>
      </main>
      <Footer />
    </div>
  )
}

export default Contact
