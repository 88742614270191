import {CheckCircleIcon, CheckIcon} from '@heroicons/react/24/solid'
import {useState} from 'react'
import {ChromeExtensionUrl} from '../../constants'
import {classNames} from '../../utils'
import Main from '../Main'
import Footer from './Footer'
import Header from './Header'
import AvailableOnChromeButton from '../AvailableOnChromeButton'

const tiers = [
  {
    name: 'Free Plan',
    id: 'tier-hobby',
    href: ChromeExtensionUrl,
    isFree: true,
    priceMonthly: 'Free',
    priceMonthlyBilledYearly: 'Free',
    description:
      "No signup needed. Just install the Fruittful Chrome extension and you're all set.",
    features: ['View full-size thumbnails on YouTube'],
  },
  {
    name: 'Standard Plan',
    id: 'tier-team',
    href: 'https://app.fruittful.com',
    isFree: false,
    priceMonthly: '$7.99',
    priceMonthlyBilledYearly: '$5.99',
    description:
      'Get access to premium features. Upgrade your thumbnail game on YouTube.',
    features: [
      'View full-size thumbnails on YouTube',
      'Bookmark YouTube thumbnails',
      'Unlimited Bookmarks',
      'Preview thumbnails directly on YouTube',
      'Unlimited Previews',
      'Upto 2000 Video Covers',
      'Unlimited Boards',
      '1 YouTube Channel',
    ],
  },
]
const Pricing = () => {
  const [isYearlyPricing, setIsYearlyPricing] = useState(true)
  return (
    <div>
      <Header />
      <Main>
        <div className="flex flex-col items-center mt-6">
          <div className="">
            <div className="relative">
              <div className="font-black text-5xl md:text-6xl text-center">
                Introductory Pricing
              </div>
              <img
                src="assets/highlight_11.svg"
                alt="highlight"
                className="absolute hidden lg:block lg:h-24 h-20 lg:top-[-40px] top-[-20px] lg:left-[-70px] left-[-15px] z-[99]"
              />
            </div>
          </div>
          <div className="mt-10">
            <div className="flex justify-center mb-5 gap-2">
              <div
                className={classNames(
                  !isYearlyPricing
                    ? 'bg-black text-white border-black'
                    : 'bg-slate-50 text-slate-500 border-slate-100 hover:bg-slate-200 hover:text-slate-600 hover:border-slate-200',
                  'shadow-md border-2  px-5 py-2 font-semibold rounded-lg hover:cursor-pointer',
                )}
                onClick={() => setIsYearlyPricing(false)}
              >
                Monthly
              </div>
              <div
                className={classNames(
                  isYearlyPricing
                    ? 'bg-black text-white border-black'
                    : 'bg-slate-50 text-slate-500 border-slate-100 hover:bg-slate-200 hover:text-slate-600 hover:border-slate-200',
                  'shadow-md border-2  px-5 py-2 font-semibold rounded-lg hover:cursor-pointer',
                )}
                onClick={() => setIsYearlyPricing(true)}
              >
                Yearly
              </div>
            </div>
            <div className="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-4xl lg:grid-cols-2">
              {tiers.map(tier => (
                <div
                  key={tier.id}
                  className="flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10 sm:p-10"
                >
                  <div>
                    <h3
                      id={tier.id}
                      className="text-base font-semibold leading-7 text-indigo-600"
                    >
                      {tier.name}
                    </h3>
                    <div className="mt-4 flex items-baseline gap-x-2">
                      <span className="text-5xl font-bold tracking-tight text-gray-900">
                        {isYearlyPricing
                          ? tier.priceMonthlyBilledYearly
                          : tier.priceMonthly}
                      </span>
                      <span className="text-base font-semibold leading-7 text-gray-600">
                        /month{' '}
                        {isYearlyPricing && !tier.isFree ? (
                          <span className="text-slate-500 text-xs">
                            (billed annualy)
                          </span>
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                    <p className="mt-6 text-sm leading-2 font-semibold  text-slate-600">
                      {tier.description}
                    </p>
                    <ul
                      role="list"
                      className="mt-10 space-y-4 text-sm leading-6 text-gray-600"
                    >
                      {tier.features.map(feature => (
                        <li key={feature} className="flex gap-x-3">
                          <CheckIcon
                            className="h-6 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                  {!tier.isFree && (
                    <div>
                      <a
                        href={tier.href}
                        aria-describedby={tier.id}
                        className="mt-8 block rounded-md bg-indigo-600 px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Get started today
                      </a>
                      <div className="flex gap-1 justify-center mt-2">
                        <CheckCircleIcon className="h-6 text-green-500" />
                        <div className="text-sm font-semibold text-slate-500 mt-[2px]">
                          14-day money back guarantee
                        </div>
                      </div>
                    </div>
                  )}
                  {tier.isFree && (
                    <div className="mt-8">
                      <AvailableOnChromeButton />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Main>
      <Footer />
    </div>
  )
}

export default Pricing
