import React from 'react'
import Footer from './Footer'
import Header from './Header'

const Refunds = () => {
  return (
    <div>
      <Header />
      <main className="mt-10 h-[calc(100vh-177px)]">
        <h1 className="text-2xl text-center font-[700]">Refund Policy</h1>
        <div className="py-4 flex flex-row justify-center">
          <section className="px-5 sm:px-10 text-gray-600 bg-slate-100 mt-1 rounded-lg">
            <p className="my-5 max-w-lg w-full mx-auto">
              We offer full refunds of any purchase within 14 days of
              purchasing. Please contact support@fruittful.com with your order
              details to make a refund request. This courtesy is offered during
              your first order only. Subsequent purchases in the future aren't
              eligible for a refund.
            </p>
          </section>
        </div>
      </main>
      <Footer />
    </div>
  )
}

export default Refunds
