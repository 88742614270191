import React from 'react'

interface Props {
  status: number
  errorMessage: string
}

const ErrorToast = ({status, errorMessage}: Props) => {
  return (
    <div>
      <span className="text-slate-700 font-semibold">
        {status}: Something Went Wrong
      </span>
      <span className="block mt-3">{errorMessage}</span>
    </div>
  )
}

export default ErrorToast
