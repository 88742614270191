import {XCircleIcon} from '@heroicons/react/24/solid'
import {ErrorBoundary} from 'react-error-boundary'
import toast, {Toaster} from 'react-hot-toast'
import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import './App.css'
import Contact from './components/pages/Contact'
import Home from './components/pages/Home'
import Privacy from './components/pages/Privacy'
import Refunds from './components/pages/Refunds'
import Terms from './components/pages/Terms'
import ErrorToast from './components/utils/ErrorToast'
import NotFound404 from './components/utils/NotFound404'
import Pricing from './components/pages/Pricing'

const queryClient = new QueryClient({
  // queryCache: new QueryCache({
  //   onError: (error: any, query) => {
  //     // 🎉 only show error toasts if we already have data in the cache
  //     // which indicates a failed background update
  //     if (query.state.data !== undefined) {
  //       toast.error(`Something went wrong: ${error.message}`)
  //     }
  //   },
  // }),
  defaultOptions: {
    queries: {
      // enabling useErrorBoundary requires
      // that ErrorBoundaries are established properly
      // use the default behavior of 'false' for now
      // useErrorBoundary: true,
      refetchOnWindowFocus: false,
      staleTime: 30000, // 30 seconds
      retry(failureCount, error: any) {
        if (error.status === 404) return false
        else if (error.status === 400) return false
        else if (failureCount < 2) return true
        else return false
      },
      onError: (error: any) => {
        if (error.response.status === 0) {
          // https://stackoverflow.com/questions/52341222/detect-xhr-status-0-in-axios
          // https://github.com/axios/axios/issues/838

          toast.error(
            `Something went wrong. Could be a CORS issue or a dropped network request. `,
          )
          return
        }

        toast.error(
          <ErrorToast
            status={error.response.status}
            errorMessage={error.response.data.errorMessage ?? error.message}
          />,
        )
        console.error(error.response.status)
        console.error(error.response.data)
      },
    },
    mutations: {
      onError: (error: any, variables, recover) => {
        if (error.response.status === 0) {
          // https://stackoverflow.com/questions/52341222/detect-xhr-status-0-in-axios
          // https://github.com/axios/axios/issues/838

          toast.error(
            `Something went wrong. Could be a CORS issue or a dropped network request. `,
          )
          return
        }

        toast.error(
          <ErrorToast
            status={error.response.status}
            errorMessage={error.response.data.errorMessage ?? error.message}
          />,
        )
        console.error(error.response.status)
        console.error(error.response.data)
        if (typeof recover === 'function') {
          recover()
        }
      },
    },
  },
})

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary FallbackComponent={FullPageErrorFallback}>
        <Toaster></Toaster>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/index.html" element={<Home />} />
            <Route path="/terms.html" element={<Terms />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy.html" element={<Privacy />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/refunds.html" element={<Refunds />} />
            <Route path="/refunds" element={<Refunds />} />
            <Route path="/contact.html" element={<Contact />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="*" element={<NotFound404 />} />
          </Routes>
        </BrowserRouter>
      </ErrorBoundary>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

function FullPageErrorFallback({error}: {error: any}) {
  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            Uh oh... There's a problem. Try refreshing the app.
          </h3>
          <div className="mt-2 text-sm text-red-700">{error.message}</div>
        </div>
      </div>
    </div>
  )
}

export default App
