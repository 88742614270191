import React from 'react'
import Footer from './Footer'
import Header from './Header'

const Terms = () => {
  return (
    <div>
      <Header />
      <main className="mt-10">
        <h1 className="text-2xl text-center font-[700]">Terms & Conditions</h1>
        <div className="py-4 flex justify-center">
          <section className="px-5 sm:px-10 text-gray-600 bg-slate-100 mt-1 rounded-lg">
            <p id="introduction" className="my-5 max-w-lg w-full mx-auto">
              <b>1. Introduction</b>
              <br />
              Your use of our Internet sites or any of the information, links,
              products or services offered on those sites (collectively, the
              “Services”) is subject to these Terms of Use (these “Terms”) by
              Rrudiment Software Private Limited, dba fruittful.com
              ("Fruittful"), at its sole discretion, may change the Terms,
              conditions and operation of this website (the “Site”) at anytime
              without notice to you. By using this Site and/or any of its
              Services, you agree to the Terms, including any modifications we
              make, and further waive any rights or claims you may have against
              Fruittful.
            </p>

            <p id="license-duration" className="my-5 max-w-lg w-full mx-auto">
              <b>2. License Duration</b>
              <br />
              This Agreement takes effect on the date on which you first use the
              application. This license is perpetual, with the exception of you
              breaking any part of this license, in which case you lose all
              rights under the license.
            </p>
            <p id="privacy" className="my-5 max-w-lg w-full mx-auto">
              <b>3. Privacy </b>
              <br />
              We are committed to protecting your privacy. Please read our
              <a
                href="https://fruittful.com/privacy.html"
                className="mx-1 text-blue-500 font-semibold underline underline-offset-1"
              >
                Privacy Policy
              </a>
              for full details.
            </p>
            <p id="youtube-terms" className="my-5 max-w-lg w-full mx-auto">
              <b>4. YouTube's Terms </b>
              <br />
              Some of our services may use YouTube's APIs to accomplish certain
              actions. By using our Services, you are agreeing to be bound by
              the
              <a
                href="https://www.youtube.com/t/terms"
                className="mx-1 text-blue-500 font-semibold underline underline-offset-1"
              >
                YouTube's Terms of Service.
              </a>
            </p>
            <p id="subscriptions" className="my-5 max-w-lg w-full mx-auto">
              <b>5. Subscriptions</b>
              <br />
              By registering for an account with Us, You become a “Subscriber”
              with access to certain password-restricted areas of the Service
              and to use certain Services and Materials offered on and through
              the Service (a “Subscription”). Each Subscription and the rights
              and privileges provided to each Subscriber are personal and
              non-transferable. You may pay for Your Subscription fees only with
              a credit card, debit card, PayPal and Apple Pay. We will charge
              Your offered method of payment for Your first Subscription fee on
              the date that We process Your order for Your Subscription. Once
              Your offered method of payment is charged the first Subscription
              fee, You will receive a confirmation e-mail notifying You of Your
              ability to access those Subscription-only portions of, and
              Materials on, the Service. IMPORTANT NOTICE: DEPENDING ON THE
              BILLING OPTION YOU CHOOSE WHEN YOU REGISTER FOR YOUR SUBSCRIPTION,
              WE WILL AUTOMATICALLY RENEW YOUR SUBSCRIPTION ON EACH MONTHLY OR
              YEARLY ANNIVERSARY OF THAT DATE THAT WE CHARGE YOUR OFFERED METHOD
              OF PAYMENT FOR THE FIRST SUBSCRIPTION FEE AND, AS AUTHORIZED BY
              YOU DURING THE MEMBERSHIP SIGN-UP PROCESS, WE WILL CHARGE YOUR
              OFFERED METHOD OF PAYMENT WITH THE APPLICABLE SUBSCRIPTION FEE AND
              ANY SALES OR SIMILAR TAXES THAT MAY BE IMPOSED ON YOUR
              SUBSCRIPTION FEE PAYMENT (UNLESS YOU CANCEL PRIOR TO THE
              ANNIVERSARY DATE). EACH SUBSCRIPTION RENEWAL PERIOD IS FOR ONE
              MONTH OR ONE YEAR, DEPENDING ON THE BILLING OPTION YOU CHOOSE. YOU
              MAY CANCEL OR DOWNGRADE YOUR SUBSCRIPTION AT ANY TIME FROM WITHIN
              THE SERVICE OR BY CONTACTING US AT SUPPORT@FRUITTFUL.COM. IF YOU
              DOWNGRADE OR CANCEL YOUR SUBSCRIPTION, YOU WILL ENJOY YOUR CURRENT
              SUBSCRIPTION BENEFITS UNTIL THE EXPIRATION OF THE THEN-CURRENT
              SUBSCRIPTION PERIOD FOR WHICH YOU HAVE PAID, AND YOUR SUBSCRIPTION
              BENEFITS WILL DOWNGRADE OR EXPIRE AT THE END OF THE THEN-CURRENT
              SUBSCRIPTION PERIOD. You are liable for paying any and all
              applicable sales and use taxes for the purchase of Your
              Subscription based on the mailing address that You provide when
              You register as a Subscriber, and You authorize US to charge Your
              offered method of payment for any such applicable taxes.
            </p>
            <p id="payments" className="my-5 max-w-lg w-full mx-auto">
              <b>6. Payments</b>
              <br />
              You agree to pay all applicable fees related to Your use of the
              Service. We may suspend or terminate Your account and/or access to
              the Service if Your payment is late and/or Your offered payment
              method (e.g., credit card, debit card, PayPal, or Apple Pay)
              cannot be processed. By providing a payment method, You expressly
              authorize Us to charge the applicable fees on said payment method
              as well as taxes and other charges incurred thereto at regular
              intervals, all of which depend on Your particular Subscription and
              utilized services. We understand that You might cancel Your
              account, but please know that You will be responsible for paying
              any balance due on the account. To make things less complicated,
              You agree that We may charge any unpaid fees to Your provided
              payment method and/or send You a bill for such unpaid fees.
            </p>
            <p
              id="refunds"
              className="my-5 max-w-lg w-full mx-auto scroll-mt-10"
            >
              <b>7. Refunds</b>
              <br />
              We offer full refunds of any purchase within 14 days of
              purchasing. Please contact support@fruittful.com with your order
              details to make a refund request. This courtesy is offered during
              your first order only. Subsequent purchases in the future aren't
              eligible for a refund.
            </p>
            <p id="disclaimer" className="my-5 max-w-lg w-full mx-auto">
              <b>8. Disclaimer</b>
              <br />
              It is not warranted that fruittful.com will meet your requirements
              or that its operation will be uninterrupted or error free. All
              express and implied warranties or conditions not stated in this
              Agreement (including without limitation, loss of profits, loss or
              corruption of data, business interruption or loss of contracts),
              so far as such exclusion or disclaimer is permitted under the
              applicable law are excluded and expressly disclaimed. This
              Agreement does not affect your statutory rights.
            </p>
            <p id="warranties" className="my-5 max-w-lg w-full mx-auto">
              <b> 9. Warranties and Limitation of Liability</b>
              <br />
              fruittful.com does not give any warranty, guarantee or other term
              as to the quality, fitness for purpose or otherwise of the
              software. fruittful.com shall not be liable to you by reason of
              any representation (unless fraudulent), or any implied warranty,
              condition or other term, or any duty at common law, for any loss
              of profit or any indirect, special or consequential loss, damage,
              costs, expenses or other claims (whether caused by fruittful.com's
              negligence or the negligence of its servants or agents or
              otherwise) which arise out of or in connection with the provision
              of any goods or services by fruittful.com. fruittful.com shall not
              be liable or deemed to be in breach of contract by reason of any
              delay in performing, or failure to perform, any of its obligations
              if the delay or failure was due to any cause beyond its reasonable
              control. Notwithstanding contrary clauses in this Agreement, in
              the event that fruittful.com are deemed liable to you for breach
              of this Agreement, you agree that fruittful.com's liability is
              limited to the amount actually paid by you for your services or
              software, which amount calculated in reliance upon this clause.
              You hereby release fruittful.com from any and all obligations,
              liabilities and claims in excess of this limitation.
            </p>
            <p
              id="general-terms-and-law"
              className="my-5 max-w-lg w-full mx-auto"
            >
              <b>10. General Terms and Law</b>
              <br />
              You acknowledge that no joint venture, partnership, employment, or
              agency relationship exists between you and Rrudiment Software
              Private Limited or fruittful.com as a result of your use of these
              services. You agree not to hold yourself out as a representative,
              agent or employee of fruittful.com. You agree that fruittful.com
              will not be liable by reason of any representation, act or
              omission to act by you.
            </p>
            <p className="my-5 max-w-lg w-full mx-auto">
              Last updated: 18th March 2024.
            </p>
          </section>
        </div>
      </main>

      <Footer />
    </div>
  )
}

export default Terms
