import React from 'react'
import {Link} from 'react-router-dom'

const Footer = () => {
  return (
    <div className="bg-black text-slate-500 font-semibold pt-6 text-sm mt-10">
      <div className="max-w-screen-xl mx-auto p-3">
        <div className=" flex justify-center gap-10">
          <a
            href="mailto:support@fruittful.com"
            className="hover:cursor-pointer hover:text-slate-400"
          >
            Contact
          </a>
          <a
            href="/privacy"
            className="hover:cursor-pointer hover:text-slate-400"
          >
            Privacy{' '}
          </a>
          <a
            href="/terms"
            className="hover:cursor-pointer hover:text-slate-400"
          >
            Terms
          </a>
        </div>
        <div className="mt-3 flex justify-center">
          © 2024 Rrudiment Software. All rights reserved.
        </div>
      </div>
    </div>
  )
}

export default Footer
