import {useNavigate} from 'react-router-dom'

const Header = () => {
  const navigate = useNavigate()
  return (
    <div className="py-5 flex justify-center sticky top-0 bg-white w-full z-[1]">
      <div className="max-w-screen-2xl flex w-full flex-col md:flex-row px-5 justify-center md:justify-between gap-10">
        <div className="w-full md:w-fit">
          <img
            src="assets/logo.svg"
            alt="logo"
            className="mt-[5px] h-15 hover:cursor-pointer"
            // navigate('/') didn't scroll to top from #pricing sections etc,
            // so we use a hard reload
            onClick={() => navigate('/')}
          ></img>
        </div>
        <div
          className={
            'flex select-none flex-row justify-center  md:mt-[10px] gap-10 md:gap-6 font-bold text-sm text-slate-500'
          }
        >
          <a
            href={'https://app.fruittful.com'}
            className="hover:cursor-pointer hover:text-slate-800"
          >
            Login
          </a>
          <div
            onClick={() => navigate('/pricing')}
            className="hover:cursor-pointer hover:text-slate-800"
          >
            Pricing
          </div>
          <a
            href="https://chrome.google.com/webstore/detail/fruittful/okjodimnfecdjfncajhoeflgbogiibfo"
            target="_blank"
            rel="noreferrer"
            className="hover:cursor-pointer hover:text-slate-800"
          >
            Chrome Extension
          </a>
          {/* <div
            onClick={() => navigate('/faq')}
            className="hover:cursor-pointer hover:text-slate-800"
          >
            FAQs
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default Header
