import {useEffect, useRef, useState} from 'react'
import toast from 'react-hot-toast'
import {FruittfulDemoUrl} from '../../constants'
import AvailableOnChromeButton from '../AvailableOnChromeButton'
import CTAButton from '../CTAButton'
import Footer from './Footer'
import Header from './Header'
import Main from '../Main'

const Home = () => {
  const videoRef = useRef<any>(null)
  const [isPaused, setIsPaused] = useState(false)

  // scroll to div with id when
  // there's a '#' present in the url
  // https://stackoverflow.com/a/73380843
  useEffect(() => {
    const href = window.location.href
    if (href.includes('#')) {
      const id = `${href.substring(href.indexOf('#') + 1)}`
      const anchor = document.getElementById(id)
      if (anchor) {
        anchor.scrollIntoView({})
      }
    }
  }, [])
  return (
    <div className="">
      <Header />
      <Main>
        <div className="grid grid-cols-1 gap-2 lg:grid-cols-2 place-items-center lg:place-items-start mt-10 lg:mt-20">
          <div className="ml-5">
            <div className="">
              <div className="font-black 2xl:text-6xl text-5xl flex flex-col gap-5 lg:gap-6  items-center lg:items-start">
                <div className="text-center">View, Preview &</div>
                {/* <span className=" relative whitespace-nowrap ml-3">
                  <span className="absolute bg-neutral-content -left-2 -top-1 -bottom-1 -right-2 md:-left-3 md:-top-0 md:-bottom-0 md:-right-3 -rotate-1 bg-black rounded-full"></span>
                  <span className="relative text-white">Bookmark</span>
                  </span>
                */}
                <div>
                  <span
                    className="bg-black rounded-full px-10 py-1 ml-[-10px] text-white hover:cursor-pointer hover:opacity-80 select-none"
                    onClick={() =>
                      toast.success('Thumbnail Bookmarked Successfully!')
                    }
                  >
                    Bookmark
                  </span>
                </div>
                <div className="lg:hidden text-center">
                  <div className="mb-1">YouTube</div>
                  <div className="relative">
                    Thumbnails{' '}
                    <img
                      src="assets/Underline_02.svg"
                      alt="underline"
                      className="absolute top-[10px] right-0 "
                      draggable={false}
                    />
                  </div>
                </div>
                <div className="hidden lg:block text-center">
                  YouTube
                  <span className="relative ml-3">
                    Thumbnails{' '}
                    <img
                      src="assets/Underline_02.svg"
                      alt="underline"
                      className="absolute top-[10px] right-0 z-[-10]"
                      draggable={false}
                    />
                  </span>
                </div>
              </div>
              <div className="mt-10">
                <div className="flex flex-col items-center lg:items-start mx-auto lg:mx-0 text-sm md:text-base text-slate-600 text-center lg:text-start font-semibold w-[75%]">
                  <div>
                    Fruittful is a Chrome extension that lets you view
                    <b className="mx-1">full-size</b>
                    YouTube thumbnails as you browse YouTube,
                    <b className="mx-1">bookmark</b>
                    the ones you like and{' '}
                    <b className="lg:mr-1 lg:mx-0 mx-1">preview</b> your own
                    thumbnails
                    <b className="mx-1">directly</b>
                    on YouTube home page.
                  </div>

                  <div className="mt-6">
                    Install the Fruittful Chrome extension and sign up to get
                    started.
                  </div>
                </div>
                <div className="mt-6 flex flex-col lg:hidden items-center justify-center lg:justify-start gap-5">
                  <AvailableOnChromeButton />
                  <CTAButton />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-10 lg:mt-0">
            <video
              src={FruittfulDemoUrl}
              className="rounded-xl hover:cursor-pointer h-[200px] md:h-[300px] lg:h-[280px] xl:h-[300px] 2xl:h-[400px]"
              autoPlay
              loop
              muted
              controls
            />
          </div>
        </div>
        <div className="hidden lg:flex mt-28 justify-center gap-5">
          <AvailableOnChromeButton />
          <CTAButton />
        </div>
      </Main>

      <Footer />
    </div>
  )
}

export default Home
