import React from 'react'
import {classNames} from '../utils'
import {ArrowRightIcon} from '@heroicons/react/24/solid'

interface IProps {
  size?: string
}
const CTAButton = ({size}: IProps) => {
  return (
    <div className="h-[56px]">
      <a href={'https://app.fruittful.com'}>
        <div className="flex flex-col">
          <div
            className={classNames(
              size === 'small'
                ? 'p-3 px-10 rounded-lg shadow-md text-sm'
                : 'p-4 px-5 lg:px-12 rounded-xl shadow-lg',
              'w-fit bg-brand-primary-600 text-white  font-semibold  hover:cursor-pointer hover:bg-brand-primary-700',
            )}
          >
            Start your 1-month free trial
            <ArrowRightIcon className="inline h-4" />
            <div className="text-xs text-center">(no credit card required)</div>
          </div>
        </div>
      </a>
    </div>
  )
}

export default CTAButton
