import React from 'react'
import {classNames} from '../utils'
import {ArrowRightIcon} from '@heroicons/react/24/solid'

interface IProps {
  size?: string
}
const AvailableOnChromeButton = ({size}: IProps) => {
  return (
    <div className="h-[72px] border-slate-200 border-2 shadow-sm rounded-xl hover:bg-slate-100">
      <a
        href={
          'https://chrome.google.com/webstore/detail/fruittful/okjodimnfecdjfncajhoeflgbogiibfo'
        }
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="assets/available-on-cws.png"
          className={'h-[72px]'}
          alt="available-on-cws-logo"
        />
      </a>
    </div>
  )
}

export default AvailableOnChromeButton
